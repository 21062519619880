@each $break, $size in $container-max-widths {
    .sld-no-gutters-#{$break} {
        @include media-breakpoint-only(#{$break}) {
            padding-right: 0;
            padding-left: 0;
        }
    }
    .sld-no-gutters-#{$break}-up {
        @include media-breakpoint-up(#{$break}) {
            padding-right: 0;
            padding-left: 0;
        }
    }
    .sld-no-gutters-#{$break}-down {
        @include media-breakpoint-down(#{$break}) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

#wrapper {
    min-height: 50vh;
}