#product {
    .page-heading {
        margin-top: 30px;
        font-size: 2rem;
        font-family: 'Amiri', 'Roboto', 'sans-serif';
        color: $color_primary;
    }
    .product-description-short {
        display: none;
    }
    .product-information {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        .add-to-cart,
        .add {
            display: none;
        }
    }
    .product-description-block {
        .block-content.box-bg {
            background-color: #F1F0E8;
            border: none;
        }
    }
    .main-product-details .product-information {
        border: none;
    }
    .product-prices .tax-label-next-price {
        display: inline-block;
    }
    .tax-shipping-delivery-label {
        display: none;
    }
    .product-extra-block .title-block {
        display: none;
    }
    .myOwnCalendarHours {
        position: initial;
    }
    .myOwnCalendarBody {
        overflow-x: auto;
        td {
            min-width: 90px;
        }
    }
    .myOwnCalendarTop {
        min-width: 480px;
    }
    .myOwnCalendarTopItem {
        background: transparent;
        border-left: none;
        border-right: none;
        border-top: none;
    }
    .myOwnCalendarLineItem {
        padding: 4px;
        height: auto;
        border-bottom: none;
        cursor: pointer;
        &:not(:last-child) {
            border-right: 1px solid #CCC;
        }
        .productSlot {
            display: block;
            min-width: 60px;
            border-radius: 4px;
            border: none;
            padding: 4px;
            border: 3px;
            cursor: pointer;
        }
        .selectedSlot {
            background: #003D23;
        }
        .availableSlot {
            background: $color_secondary;
        }
        .slotLabel {
            display: block;
            cursor: pointer;
        }
        .timeLabel {
            display: block;
            text-align: center;
        }
        .quantityLabel {
            display: flex !important;
            align-items: center;
            justify-content: center;
            white-space: initial;
            width: 100%;
        }
    }
    table.myOwnCalendarLine .selectedSlot span.timeLabel {
        display: block !important;
    }
    .sld-planning-content {
        overflow-y: scroll;
        min-width: 500px;
        max-height: 400px;
    }
    .sld-icon-ticket {
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        margin-left: 6px;
    }
    @include media-breakpoint-down(md) {
        .center-column {
            background-color: $color_white;
        }
    }
    @include media-breakpoint-up(lg) {
        .product-right {
            padding-right: 30px;
        }
    }
}