.sld-icon {
    display: inline-block;
    min-width: 10px;
    min-height: 10px;
}

.sld-icon-phone {
    background-image: url(../img/sld-phone.svg);
    background-size: 10px 10px;
}

.sld-icon-user {
    background-image: url(../img/sld-user.svg);
    background-size: 10px 10px;
}

.sld-icon-shopping {
    background-image: url(../img/sld-shopping.svg);
    background-size: 10px 10px;
}

.sld-icon-bubble-phone {
    background-image: url(../img/sld-bubble-phone.svg);
    background-size: 10px 10px;
}

.sld-icon-ticket {
    background-image: url(../img/sld-ticket.svg);
    background-size: 10px 10px;
}