#cart {
    .product-line-grid {
        .product-line-grid-right {
            width: 100%;
            flex-wrap: wrap;
        }
        .sld-cart-users {
            display: flex;
            flex-wrap: wrap;
        }
        .attendee_form {
            display: flex;
            flex-direction: column;
        }
    }
    .sld-cart-info-custom {
        width: 100%;
    }
    .reservation_label {
        width: 100%;
    }
    .sld-cart-user {
        display: flex;
        flex-wrap: wrap;
        width: 245px;
        padding: 10px 20px;
        max-width: 245px;
        .page-subheading {
            text-transform: capitalize;
            color: $color_primary;
            font-size: 1.4rem !important;
            font-weight: 500;
        }
        label {
            text-align: left;
        }
        .form-group {
            margin-bottom: 0;
        }
        .sld-icon-user {
            background-size: 30px 30px;
            width: 30px;
            height: 30px;
            margin-bottom: 12px;
        }
    }
    .sld-cart-user-gender {
        > label {
            display: none;
        }
        .radio-group {
            display: flex !important;
        }
        .radio-inline {
            margin-right: 20px;
        }
    }
    .sld-cart-user-firstname,
    .sld-cart-user-lastname {
        position: relative;
        max-width: 200px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
