#checkout .cart-preview {
  .cart-dropdown {
    display: none !important;
  }
}

#cart-summary-product-list {
  .modal-body {
    padding: 0;
  }
}

.checkout-step-order {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .step-part {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    min-width: 20%;
    padding-top: 4px;
    background: inherit;
    .part-icon {
      position: relative;
      line-height: 22px;
      padding-top: 1px;
      height: 24px;
      background: inherit;
      color: inherit;
    }
    .circle {
      position: absolute;
      width: 24px;
      height: 24px;
      border: 1px solid;
      left: 0;
      right: 0;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      background: inherit;
      color: inherit;
    }
    .line {
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      border-top: 1px solid;
      color: inherit;
    }
    .done {
      font-size: 150%;
      margin: 0;
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
      color: inherit;
      display: none;
    }
    .position {
      font-size: 120%;
      position: relative;
      color: inherit;
      display: block;
      text-align: center;
      opacity: 0;
    }
    .edit {
      font-size: 120%;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
      color: $icon-color;
    }
    .part-text {
      font-weight: 700;
      margin-top: 8px;
      text-align: center;
    }

    &.current {
      cursor: default;
      .part-icon {
        color: $color_pink;
      }
      .position {
        opacity: 1;
      }
    }
    &.current.reachable.complete {
      .part-icon {
        color: $icon-color;
      }
      .done {
        display: none;
      }
      .position {
        display: block;
        opacity: 1;
      }
    }
    &.reachable.complete {
      .part-icon {
        color: #28a745;
      }
      .done {
        display: block;
      }
      .position {
        display: none;
      }
      &:not(.current):hover {
        .done {
          opacity: 0;
        }
        .edit {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &.unreachable {
      pointer-events: none;
      opacity: 0.5;
      .position {
        opacity: 1;
      }
    }
  }
}

.checkout-step {
  .content,
  .step-title {
    display: none;
  }
  .not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.-current {
    .step-title, .content {
      display: block;
    }
  }
}

@include media-breakpoint-down(md) {
  #checkout .cart-grid.row {
    flex-direction: column-reverse;
  }
  .checkout-step-order {
    padding: 5px;
    .step-part .part-text span {
      font-size: 90%;
    }
  }
}

.checkout-step-display {
  .content {
    margin: 10px 0;
  }
  .step-button-continue {
    margin-top: 30px; 
  }
  .delivery-option {
    padding: $box-padding 0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      border-top: 1px solid $border-color-lighten;
    }
    &:first-child:before {
      display: none;
    }
    label {
      text-align: initial;
      margin-bottom: 0;
    }
    .custom-radio {
      padding: 5px 0.5rem;
    }
    .carrier-logo {
      text-align: center;
      min-width: 80px;
      margin: 0 10px;
      img {
        max-width: 100%;
      }
    }
    .carrier-delay, .carrier-name, .carrier-price {
      padding: 5px 0.5rem;
    }
    .carrier-name {
      font-weight: 700;
    }
    .carrier-delay, .carrier-name {
      word-break: break-word;
      width: 40%;
    }
    .carrier-delay, .carrier-price {
      font-weight: 400;
    }
    @include media-breakpoint-down(md) {
      .carrier-name {
        width: 100%;
      }
      .carrier-delay {
        width: auto;
      }
    }
  }
}

#checkout-personal-information-step {
  .step-title {
    display: none;
  }
  .personal-form {
    display: none;
    &.active {
      display: block;
    }
    .light-box-bg, .box-bg {
      padding-top: 5px;
    }
  }
}
#checkout-addresses-step {
  .step-title {
    display: none;
  }
  .address-item {
    margin-bottom: 15px;
    opacity: 0.6;
    border-width: 2px;
    &:hover {
      opacity: 1;
    }
    &.selected {
      border-color: $color_blue;
      opacity: 1;
    }
    .address-header {
      label {
        width: 100%;
        margin-bottom: $box-padding;
      }
    }
    .address-footer {
      border-top: 1px solid $border-color-lighten;
      padding-top: $box-padding;
      a {
        display: inline-block;
      }
      a + a {
        margin-left: 10%;
      }
      .material-icons, .fa {
        margin-top: -2px;
      }
    }
  }
  .cancel-address {
    margin-top: 10px;
    display: block;
  }
}
#checkout-delivery-step {
  .delivery-options {
    .row {
      align-items: center;
      flex-wrap: nowrap;
    }
  }
  .order-options {
    padding-top: $box-padding;
    border-top: 1px solid $border-color-lighten;
    textarea.form-control {
      height: auto;
    }
  }
  #gift label {
    margin-top: 10px;
  }
}
#checkout-payment-step {
  .payment-options {
    padding: 0 0.9375rem;
    margin-bottom: 30px;
  }
  .payment-line {
    padding: 0.9375rem 0;
    + .payment-line {
      border-top: 1px solid $border-color-lighten;
    }
  }
  .payment-option {
    .option-logo {
      max-height: $line-height-base + rem;
      margin: 0 5px;
    }
  }
  .additional-information {
    padding: 10px 24px 0;
  }
  #modal-payment-terms .modal-header {
    padding: 2px 5px;
    border: none;
  }
  .payment-final {
    .custom-checkbox {
      margin: auto;
    }
  }
}

#order-summary-content {
  border: 3px solid theme-color-level("info", $alert-bg-level);
  padding: 0 2% 20px;
  overflow: hidden;
  margin-top: 32px;
  .summary-message {
    margin: 0 -4% 20px
  }
  .osb-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h5 {
      margin: 0;
    }
    .step-edit {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .summary-selected-carrier {
    padding: 0 5px;
    .delivery-option {
      align-items: center;
      display: flex;
    }
  }
}


/**** ORDER CONFIRMATION *****/

#order-items {
}
#order-confirmation {
  #order-items {
    margin-bottom: 1rem;
  }
  .product-line-grid .product-attributes {
    display: none;
  }
  #order-details {
    margin-bottom: 1rem;
    ul {
      li + li {
        margin-top: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #order-items {
    .details {
      margin-bottom: 0.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  section.checkout-step .address-item {
    flex: 0 0 99%;
  }
}

.js-payment-binary {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}
