$icon-size: 30px;
#header{
    @include media-breakpoint-down(sm) {
        .header-wrapper {
            flex-wrap: wrap;
        }
        .header-logo {
            margin-left: auto;
            margin-right: auto;
        }
        .header-right {
            width: 100%;
            .display-top {
                justify-content: center;
            }
        }
        .desktop-sticky-wrapper {
            [data-sticky-menu] {
                transition: 0.3s;
                top: 0;
            }
            &.is-sticky [data-sticky-menu] {
                top: -90px !important;
            }
        }
    }
    .mobile-header-version .mobile-sticky-wrapper.is-sticky .sticky-background {
        height: 80px;
    }
    .display-top > div {
        margin-left: 0;
        padding-left: 2%;
        padding-right: 2%;
        &:not(:first-child) {
            border-left: 2px solid $color_primary;
        }
    }
    .sld-account-cookies {
        img {
            height: 15px;
            width: auto;
            float: right;
        }
    }
    .user-info .account-link {
        border: none;
        a {
            font-size: 1rem;
            flex-direction: column;
            white-space: pre-wrap;
        }
    }

    .cart-preview .cart-header .cart-link {
        flex-direction: column;
        background-color: transparent;
        color: $color_primary;
        font-size: 1rem;
        font-weight: 400;
        width: auto;
        height: auto;
        padding: 7px 8px;
    }
    .sld-header-contact-link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 7px;
        .sld-icon {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .sld-header-contact-text {
        font-size: 1rem;
        color: $color_primary;
        text-align: center;
    }
    .header-bottom {
        display: none;
    }

    // mobile version
    .mobile-header-version {
        // remove menu mobile
        .mobile-display-left {
            display: none !important;
        }
        // remove position absolute
        .customer-signin-module {
            position: initial;
        }
    }
    .sld-icon-user,
    .sld-icon-shopping,
    .sld-icon-phone {
        background-size: $icon-size $icon-size;
        width: $icon-size;
        height: $icon-size;
    }
    .cart-design {
        height: $icon-size;
    }
    .sld-account-cookies {
        .link-item {
            
        }
    }
}