$icon-size: 30px;
#footer {
    .sld-footer-title {
        font-size: 2rem;
        line-height: 2rem;
    }
    .sld-footer-mail {
        white-space: nowrap;
        padding: 10px 20px;
        color: $color_primary;
        font-weight: 600;
    }
    .sld-footer-container-tel {
        max-width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .sld-icon-bubble-phone {
        background-size: $icon-size $icon-size;
        width: $icon-size;
        height: $icon-size;
        margin-right: 20px;
    }
    .sld-footer-tel {
        color: $color_white;
        font-size: 1.6rem;
    }
    .sld-footer-phone-sub-title {
        display: block;
        font-size: 0.9rem;
    }
    .checkout-footer-content,
    .footer-bottom a {
        color: $color_white;
    }
}